import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import paymentsApiClient from '@/features/payments/api';
import { MembershipPlan } from '@/features/payments/dtos/types';
import { Logger } from '@/utils/logger';

import { useToast } from './use-toast';

export const useProceedPayment = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { t } = useTranslation();

  const getPaymentUrlMutation = paymentsApiClient.useGetPaymentUrl();

  return {
    proceedWithPayment: (plan: MembershipPlan) => {
      getPaymentUrlMutation.mutate(
        {
          plan,
        },
        {
          onSuccess: data => {
            sessionStorage.removeItem('paymentProcess');

            if (data?.data?.checkout_url) {
              router.push(data?.data?.checkout_url);

              return;
            }

            toast({
              title: t('ERRORS.UNEXPECTED_ERROR.TITLE'),
              description: t('ERRORS.UNEXPECTED_ERROR.DESCRIPTION'),
              variant: 'destructive',
            });
          },
          onError: error => {
            sessionStorage.removeItem('paymentProcess');

            toast({
              title: t('ERRORS.UNEXPECTED_ERROR.TITLE'),
              description: t('ERRORS.UNEXPECTED_ERROR.DESCRIPTION'),
              variant: 'destructive',
            });
            Logger.captureException(error);
          },
        },
      );
    },
  };
};
