import { InvalidateQueryFilters, useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import api from '@/utils/fetch';

import { AuthenticationQueryKeys } from './query-keys';
import { Credentials } from '../dtos/auth.type';

export function useLogin() {
  const client = useQueryClient();
  const { locale } = useRouter();

  return useMutation({
    mutationFn: (credentials: Credentials) =>
      api.post('/login', credentials, {
        headers: {
          'Accept-Language': locale,
        },
      }),
    onSuccess: () =>
      client.invalidateQueries(AuthenticationQueryKeys.Login as InvalidateQueryFilters),
  });
}
