import { Eye, EyeOff } from 'lucide-react';
import { useTranslation } from 'next-i18next';
import { forwardRef, ReactNode, useState } from 'react';
import { InputProps } from '../ui/input';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
interface DefaultProps extends InputProps {
  icon?: ReactNode;
  isPassword?: boolean;
  label?: string;
  validIcon?: ReactNode;
}
type PasswordType = 'password' | 'text';
const InputBox = forwardRef<HTMLInputElement, DefaultProps>(({
  id,
  icon,
  validIcon,
  isPassword,
  label,
  type,
  ...props
}, ref) => {
  const {
    t
  } = useTranslation();
  const [passwordType, setPasswordType] = useState<PasswordType>('password');
  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };
  return <div className="input-box-custom border-b-2  border-text">
        {label && <Label htmlFor={id} className="text-text" suppressHydrationWarning>
            {t(label)}
          </Label>}
        <div className="flex items-center gap-[0]">
          {icon && <div>{icon}</div>}
          <div className="w-full relative">
            <Input id={id} ref={ref} type={isPassword ? passwordType : type} autoComplete={props.autoComplete} {...props} className="block w-full text-text bg-transparent border-none" />
            {validIcon && <div className="absolute top-[15px] right-[4px]">{validIcon}</div>}
            {isPassword && <div className="absolute top-[15px] right-[25px]">
                {passwordType === 'password' ? <EyeOff size={18} className="cursor-pointer" onClick={togglePassword} /> : <Eye size={18} className="cursor-pointer" onClick={togglePassword} />}
              </div>}
          </div>
        </div>
      </div>;
});
InputBox.displayName = 'InputBox';
export default InputBox;