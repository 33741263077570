import { useState, useEffect } from 'react';

import { PortfolioListItem } from '@/features/supplier/dtos/portfolio.type';

const STORAGE_KEY = 'exampleOrder';
/**
 * Used for storing an example order data in session storage.
 * That data then is used to generate a create order request.
 */
const useExampleOrder = () => {
  const [order, setOrder] = useState<PortfolioListItem | null>(null);

  useEffect(() => {
    const storedOrder = sessionStorage.getItem(STORAGE_KEY);
    if (storedOrder) {
      setOrder(JSON.parse(storedOrder));
    }
  }, []);

  const setExampleOrder = (data: PortfolioListItem) => {
    const newOrder = data;

    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(newOrder));

    setOrder(newOrder);
  };

  const getExampleOrder = () => {
    return order;
  };

  const deleteExampleOrder = () => {
    sessionStorage.removeItem(STORAGE_KEY);
    setOrder(null);
  };

  return {
    setExampleOrder,
    getExampleOrder,
    deleteExampleOrder,
  };
};

export default useExampleOrder;
