export const getEmailPlaceholder = (language: string) => {
  return language === 'en' ? 'Enter your email address' : 'Въведи твоя имейл адрес';
};

export const getPasswordPlaceholder = (language: string) => {
  return language === 'en' ? 'Enter your password' : 'Въведи твоята парола';
};

export const loginPage = {
  en: {
    title: 'Sign in',
    text: "If you don't have an account,\n please register.",
    button: 'Register here !',
    form: {
      emailLabel: 'Email',
      emailPlaceholder: 'Enter your email address',
      passwordLabel: 'Password',
      passwordPlaceholder: 'Enter your password',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot Password ?',
      errorInvalidEmail: 'Invalid email address',
      errorInvalidFirstName: 'The first name should contain from 2 to 20 characters',
      errorInvalidLastName: 'The last name should contain from 2 to 20 characters',
      errorInvalidPassword: 'The password should be at least 8 symbols long',
      noUserError: 'The user does not exist',
      loginButton: 'LOGIN',
      continue: 'Or Continue With',
    },
  },
  bg: {
    title: 'Вход',
    text: 'Ако нямате акаунт,\n моля регистрирайте се',
    button: 'Регистрирай се тук!',
    form: {
      emailLabel: 'Имейл',
      emailPlaceholder: 'Въведи твоя имейл адрес',
      passwordLabel: 'Парола',
      passwordPlaceholder: 'Въведи твоята парола',
      rememberMe: 'Запомни ме',
      forgotPassword: 'Забравена парола ?',
      errorInvalidEmail: 'Въведете валиден имейл адрес',
      errorInvalidFirstName: 'Името трябва да съдържа от 2 до 20 символа',
      errorInvalidLastName: 'Фамилията трябва да съдържа от 2 до 20 символа',
      errorInvalidPassword: 'Паролата трябва да съдържа поне 8 символа',
      noUserError: 'Потребителят не съществува',
      loginButton: 'ВХОД',
      continue: 'Или продължи с',
    },
  },
};
