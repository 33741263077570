import {
  useQueryClient,
  useMutation,
  InvalidateQueryFilters,
  useQuery,
} from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';

import { COOKIE_ACCESS_TOKEN } from '@/features/auth/utils/constants';
import api from '@/utils/fetch';
import { generateQueryString } from '@/utils/helpers';

import { PaymentsQueryKeys } from './query-keys';
import {
  GeneratePlanUrlParams,
  GeneratePlanUrlResponse,
  GetAllPaymentResponse,
  Payment,
} from '../dtos/types';

function useGetPaymentUrl() {
  const { locale } = useRouter();
  const client = useQueryClient();

  return useMutation<GeneratePlanUrlResponse, unknown, GeneratePlanUrlParams>({
    mutationFn: (input: GeneratePlanUrlParams) => {
      const token = Cookies.get(COOKIE_ACCESS_TOKEN);

      const querystring = generateQueryString({
        product_name: input.plan,
      });

      return api.post(`/stripe/checkout${querystring}`, null, {
        headers: {
          'Accept-Language': locale,
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: () => client.invalidateQueries(PaymentsQueryKeys.GetUrl as InvalidateQueryFilters),
  });
}

function useGetPayment() {
  const { locale } = useRouter();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);

  const router = useRouter();
  const paymentId = router.query.id;

  return useQuery<Payment>({
    queryKey: [PaymentsQueryKeys.GetPayment, locale, paymentId, token],
    queryFn: async () => {
      const response = await api.post(`/stripe/payment/${paymentId}`, null, {
        headers: {
          'Accept-Language': locale,
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
  });
}

function useGetPayments(params?: { [key: string]: boolean | number | string }) {
  const { locale } = useRouter();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);

  const querystring = generateQueryString(params);

  return useQuery<GetAllPaymentResponse>({
    queryKey: [PaymentsQueryKeys.GetAllPayments, locale, querystring, token],
    queryFn: async () => {
      const response = await api.get(`/stripe/payment${querystring}`, {
        headers: {
          'Accept-Language': locale,
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    },
  });
}

function useCancelSubscription() {
  const { locale } = useRouter();
  const client = useQueryClient();
  const token = Cookies.get(COOKIE_ACCESS_TOKEN);

  return useMutation({
    mutationFn: async ({ immediately }: { immediately: boolean }) => {
      const data = new FormData();
      if (immediately) {
        data.append('immediately', 'true');
      }

      const response = await api.delete('/subscription/cancel', {
        headers: {
          'Accept-Language': locale,
          Authorization: `Bearer ${token}`,
        },
        data,
      });

      return response.data;
    },
    onSuccess: () =>
      client.invalidateQueries(PaymentsQueryKeys.CancelSubscription as InvalidateQueryFilters),
  });
}

const apiClient = {
  useGetPaymentUrl,
  useGetPayment,
  useGetPayments,
  useCancelSubscription,
};

export default apiClient;
