export const registerPage = {
  en: {
    TITLE: 'Sign up',
    TEXT: 'If you have an account, you can',
    BUTTON: 'Login Here !',
    FORM_ONE: {
      EMAIL_LABEL: 'Email',
      EMAIL_PLACEHOLDER: 'Enter your email address',
      PASSWORD_LABEL: 'Password',
      PASSWORD_PLACEHOLDER: 'Enter your password',
      ERROR_INVALID_CONFIRM_PASSWORD: 'The confirm password does not match the password',
      CONFIRM_PASSWORD_LABEL: 'Confirm Password',
      CONFIRM_PASSWORD_PLACEHOLDER: 'Repeat password',
      FIRST_NAME_LABEL: 'First name',
      FIRST_NAME_PLACEHOLDER: 'Enter your first name',
      LAST_NAME_LABEL: 'Last name',
      LAST_NAME_PLACEHOLDER: 'Enter your last name',
      NAME_ERROR: 'Should contain from 2 to 20 characters',
      REGISTER_ERROR: 'Registration failed. Please check if the account is already registered',
      SUBMIT: 'REGISTER',
      CONTINUE: 'Or Continue With',
    },
    FORM_TWO: {
      TITLE: 'Verify Your Account',
      TEXT: 'Enjoy the convenienve of a single account accross all participating brands.',
      LABEL_PHONE: 'Mobile Number',
      LABEL_PHONE_EXTRA: "(We'll send you a verification code)",
      PHONE_PLACEHOLDER: 'Mobile Number',
      SUBMIT: 'SENT',
    },
    FORM_THREE: {
      TEXT: 'Please enter your code here',
      RESEND_MESSAGE: 'Resend code:',
      RESEND_LEFT_SECONDS: 'seconds left',
      RESEND_NEW_CODE: 'send new code',
      SUBMIT: 'LOGIN',
    },
    RETURN_TO_REGISTRATION: {
      TITLE: 'Registration is not completed!',
      TEXT_ONE: 'Oops, seems like you have started to register as:',
      TEXT_TWO: 'Do you want to return and finish your registration?',
      DO_NOT_SHOW: "Don't show again",
      CONTINUE: 'Continue',
      COMPLETE: 'Complete registration',
    },
  },
  bg: {
    TITLE: 'Регистрация',
    TEXT: 'Ако имате акаунт, впишете се в системата',
    BUTTON: 'Вход тук !',
    FORM_ONE: {
      EMAIL_LABEL: 'Имейл',
      EMAIL_PLACEHOLDER: 'Въведи твоя имейл адрес',
      PASSWORD_LABEL: 'Парола',
      PASSWORD_PLACEHOLDER: 'Въведи твоята парола',
      ERROR_INVALID_CONFIRM_PASSWORD: 'Въведените пароли не съвпадат',
      CONFIRM_PASSWORD_LABEL: 'Потвърди паролата',
      CONFIRM_PASSWORD_PLACEHOLDER: 'Повтори паролата',
      FIRST_NAME_LABEL: 'Име',
      FIRST_NAME_PLACEHOLDER: 'Въведи твоето име',
      LAST_NAME_LABEL: 'Фамилия',
      LAST_NAME_PLACEHOLDER: 'Въведи твоята фамилия',
      NAME_ERROR: 'Трябва да съдържа от 2 до 20 символа',
      REGISTER_ERROR:
        'Регистрацията се провали. Моля проверете дали вече не съществува регистрация',
      SUBMIT: 'РЕГИСТРАЦИЯ',
      CONTINUE: 'Или продължи с',
    },
    FORM_TWO: {
      TITLE: 'Потвърдете акаунта си',
      TEXT: 'Насладете се на удобството на един акаунт за всички наши услуги',
      LABEL_PHONE: 'Телефонен номер',
      LABEL_PHONE_EXTRA: '(Ще ви изпратим код за потвърждение)',
      PHONE_PLACEHOLDER: 'Телефонен номер',
      SUBMIT: 'ИЗПРАТИ',
    },
    FORM_THREE: {
      TEXT: 'Моля, въведете своя код тук',
      RESEND_MESSAGE: 'Повторно изпращане на кода:',
      RESEND_LEFT_SECONDS: 'секунди остават',
      RESEND_NEW_CODE: 'изпрати нов код',
      SUBMIT: 'ВХОД',
    },
    RETURN_TO_REGISTRATION: {
      TITLE: 'Регистрацията не е завършена!',
      TEXT_ONE: 'Ами, изглежда, че сте започнали да се регистрирате като:',
      TEXT_TWO: 'Искате ли да се върнете и да завършите регистрацията си?',
      DO_NOT_SHOW: 'Не показвай отново',
      CONTINUE: 'ПРОДЪЛЖИ КЪМ СТРАНИЦАТА',
      COMPLETE: 'ПРОДЪЛЖИ РЕГИСТРАЦИЯТА',
    },
  },
};
