import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import LoaderContainer from '@/containers/loader';
import { registerPage } from '../data/register';
import { isPendingRegistration, removePendingRegistrationPrompt } from '../services/registration';
const ReturnRegistrationModal: FC<{
  hasReturnedToRegistration?: boolean;
}> = ({
  hasReturnedToRegistration = true
}) => {
  const {
    i18n
  } = useTranslation();
  const language = i18n.language as 'bg' || 'en';
  const registrationData = isPendingRegistration();
  const [isOpen, setIsOpen] = useState<boolean>(!hasReturnedToRegistration ? false : registrationData.status);
  const [isLoading, setLoading] = useState(false);
  const [neverShow, setNeverShow] = useState(false);
  const router = useRouter();
  useEffect(() => {
    const currentStep = router.query.regStep;
    const shouldShowModal = !hasReturnedToRegistration ? false : registrationData.status;
    const isOnRegistrationPage = currentStep && (currentStep === '2' || currentStep === '3');
    setIsOpen(shouldShowModal && !isOnRegistrationPage);
  }, [hasReturnedToRegistration, registrationData.status, router.query.regStep]);
  const closeModal = () => {
    if (neverShow) {
      removePendingRegistrationPrompt();
    }
    setIsOpen(false);
  };
  const navigateToStep = () => {
    setLoading(true);
    if (registrationData.status) {
      if (neverShow) {
        removePendingRegistrationPrompt();
      }
      const step = registrationData.stepTwoToken ? 2 : 3;
      router.push('/register?regStep=' + step);
    }
  };
  return <Dialog open={isOpen} data-sentry-element="Dialog" data-sentry-component="ReturnRegistrationModal" data-sentry-source-file="return-registration-modal.tsx">
      <DialogContent className="rounded-md bg-white w-fit max-w-[90vw]" data-sentry-element="DialogContent" data-sentry-source-file="return-registration-modal.tsx">
        <DialogHeader className="relative" data-sentry-element="DialogHeader" data-sentry-source-file="return-registration-modal.tsx">
          <DialogTitle className="text-center text-2xl" data-sentry-element="DialogTitle" data-sentry-source-file="return-registration-modal.tsx">
            {registerPage[language].RETURN_TO_REGISTRATION.TITLE}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription data-sentry-element="DialogDescription" data-sentry-source-file="return-registration-modal.tsx">
          <p className="text-base">
            {registerPage[language].RETURN_TO_REGISTRATION.TEXT_ONE}{' '}
            <strong>{registrationData.status ? registrationData.email : ''}</strong>.
          </p>
          <p className="text-base mt-2">{registerPage[language].RETURN_TO_REGISTRATION.TEXT_TWO}</p>
          <div className="flex gap-2 mt-4 items-center mb-4">
            <Checkbox onClick={() => {
            setNeverShow(!neverShow);
          }} checked={neverShow} className="rounded-[5px] text-white h-[20px] w-[20px] checkbox-inside-white" data-sentry-element="Checkbox" data-sentry-source-file="return-registration-modal.tsx" />
            <span onClick={() => {
            setNeverShow(!neverShow);
          }} className="text-base cursor-pointer leading-none peer-disabled:cursor-not-allowed">
              {registerPage[language].RETURN_TO_REGISTRATION.DO_NOT_SHOW}
            </span>
          </div>
          <LoaderContainer isLoading={isLoading} data-sentry-element="LoaderContainer" data-sentry-source-file="return-registration-modal.tsx">
            <div className="flex flex-col md:flex-row gap-2 mt-6 justify-center">
              <Button variant="ghost" className="uppercase" onClick={closeModal} data-sentry-element="Button" data-sentry-source-file="return-registration-modal.tsx">
                {registerPage[language].RETURN_TO_REGISTRATION.CONTINUE}
              </Button>
              <Button variant="default" onClick={navigateToStep} data-sentry-element="Button" data-sentry-source-file="return-registration-modal.tsx">
                {registerPage[language].RETURN_TO_REGISTRATION.COMPLETE}
              </Button>
            </div>
          </LoaderContainer>
        </DialogDescription>
      </DialogContent>
    </Dialog>;
};
export default ReturnRegistrationModal;