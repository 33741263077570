import { useRouter } from 'next/router';

export function useGetCanonicalUrl() {
  const router = useRouter();
  const origin = process.env.NEXT_PUBLIC_URL;

  if (router.locale !== router.defaultLocale) {
    return `${origin}/${router.locale}${router.asPath}`;
  }

  return `${origin}${router.asPath}`;
}
