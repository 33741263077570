import { Street } from '@/features/orders/dtos/orders.type';
import nextI18NextConfig from '~/next-i18next.config.js';

import { ORDER_STATUS, ORDER_STATUSES } from './constants/base';

export function formatTime(timestamp: Date | number | string) {
  if (typeof timestamp === 'number') {
    timestamp *= 1000;
  }

  let date = timestamp;

  if (!(date instanceof Date)) {
    date = new Date(timestamp);
  }

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return null;
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
}

export function getOrderAddress(data: Street, isEnglish: boolean) {
  if (isEnglish) {
    return `${data.street_type.name_en} ${data.name_en}, ${data.city.city_type.name_en} ${data.city.name_en}`;
  } else {
    return `${data.street_type.name} ${data.name}, ${data.city.city_type.name} ${data.city.name}`;
  }
}

export const getAvarageReviewScore = (scores: number[]): number => {
  const validScores = scores.filter(
    score => typeof score === 'number' && !isNaN(score) && score >= 1 && score <= 5,
  );

  // Return 0 if no valid scores
  if (validScores.length === 0) return 0;

  // Calculate average and round to 1 decimal place
  const sum = validScores.reduce((acc, score) => acc + score, 0);
  const average = sum / validScores.length;

  return Math.round(average * 10) / 10;
};

export const getDaysAgoString = (timestamp: Date | number, language: string = 'en'): string => {
  let myTimestamp = timestamp;
  if (typeof timestamp === 'number') {
    const fullTimestamp = timestamp + '000';
    myTimestamp = Number(fullTimestamp);
  }
  const baseDate = new Date(myTimestamp); // Convert timestamp to a Date object
  const resultDate = new Date(baseDate); // Copy the base date
  resultDate.setDate(baseDate.getDate()); // Subtract the number of days

  const now = new Date(); // Get current date
  const diffTime = now.getTime() - resultDate.getTime(); // Time difference in milliseconds
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert to days

  return diffDays === 0
    ? language === 'en'
      ? 'Today'
      : 'Днес'
    : diffDays === 1
      ? language === 'en'
        ? '1 day ago'
        : 'Вчера'
      : language === 'en'
        ? `${diffDays} days ago`
        : `преди ${diffDays} дни`;
};

export function truncateText(text: string | undefined, maxLength: number = 100): string {
  if (!text) {
    return '';
  }
  if (text.length <= maxLength) return text;

  let truncatedText = text.slice(0, maxLength);

  if (truncatedText[truncatedText.length - 1] === '<') {
    truncatedText = truncatedText.slice(0, -1);
  } else if (truncatedText[truncatedText.length - 1] === '/') {
    truncatedText = truncatedText.slice(0, -2);
  }

  return truncatedText + '...';
}

export function prepareRichTextHtml(str: string): string {
  return str
    ?.replaceAll('<p></p>', '<br>')
    ?.replaceAll(/<script\b[^>]*>([\s\S]*?)<\/script>/gi, '');
}

export function removeHtmlFromText(str?: string) {
  if (!str) return '';

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str
    .replaceAll('<p></p>', '')
    .replaceAll(/<[^/][^>]*>/gi, '')
    .replaceAll(/<\/[^>]+>/g, ' ')
    .trim();
}

/**
 * Retrieves a translated property from an object based on the specified language.
 * If the specified language is the default language, it fetches the base property.
 * Otherwise, it fetches the property with the language-specific suffix.
 *
 * @param {any} obj - The object containing the properties.
 * @param {string} key - The base key for the property to retrieve.
 * @param {string} lang - The language code used to determine the translation.
 * @returns {any} The value of the translated property, the base property if in the default language, or an empty string if not found.
 *
 * @example
 * const obj = { name: "Name", name_en: "Name in English" };
 * getTranslatedPropertyFromObj(obj, "name", "en"); // "Name in English"
 * getTranslatedPropertyFromObj(obj, "name", "bg"); // "Name"
 */
//TODO: fix any
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getTranslatedPropertyFromObj(obj: any, key: string, lang: string): any {
  if (!obj) {
    return '';
  }

  const isDefaultLanguage = checkIfDefaultLanguage(lang);

  if (isDefaultLanguage) {
    return obj[key];
  }

  const property = obj[key + `_${lang}`];

  return property || '';
}

/**
 * Checks whether the provided language code matches the default language.
 *
 * @param {string} lang - The language code to check.
 * @returns {boolean} `true` if the provided language is the default language, `false` otherwise.
 *
 * @example
 * checkIfDefaultLanguage("bg"); // true (assuming defaultLocale is "bg")
 * checkIfDefaultLanguage("en"); // false (assuming defaultLocale is "bg")
 */
export function checkIfDefaultLanguage(lang: string) {
  return lang === nextI18NextConfig.i18n.defaultLocale;
}

/**
 * Generates a query string from the keys and values of an object.
 *
 * @param {Object} params - An object containing key-value pairs to be converted into a query string.
 * @param {string} params.key - The key of the query parameter.
 * @param {string} params.value - The value of the query parameter.
 * @returns {string} A URL-encoded query string.
 *
 * @example
 * const queryParams = { name: "John Doe", age: "30", city: "New York" };
 * const queryString = generateQueryString(queryParams);
 * console.log(queryString);
 * // Output: "?name=John%20Doe&age=30&city=New%20York"
 */
export function generateQueryString(
  params?: { [key: string]: boolean | number | string } | null | undefined,
) {
  if (!params) return '';

  return (
    '?' +
    Object.keys(params)
      .filter(key => (typeof params[key] === 'number' ? true : !!params[key]))
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&')
  );
}

export function getOrderStatus(status: ORDER_STATUS) {
  return ORDER_STATUSES?.find(item => item.id === status)!;
}
