import Cookies from 'js-cookie';
import { LockKeyhole, Mail } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import InputBox from '@/components/input-box';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import LoaderContainer from '@/containers/loader';
import { MembershipPlan } from '@/features/payments/dtos/types';
import useExampleOrder from '@/hooks/use-example-order';
import { useProceedPayment } from '@/hooks/use-proceed-payment';
import { Logger } from '@/utils/logger';
import ReturnRegistrationModal from './return-registration-modal';
import { useLogin } from '../api/use-login';
import { useAuth } from '../context/auth';
import { loginPage } from '../data/login';
import { Credentials } from '../dtos/auth.type';
import { COOKIE_NEW_REGISTERED_SUPPLIER_ID, REGISTRATION_TOKEN_STEP_2, REGISTRATION_TOKEN_STEP_3 } from '../utils/constants';
import { getRememberMe, setRememberMeToken, setUserData, setUserProfileType } from '../utils/helpers';
export const SignIn = () => {
  const {
    i18n,
    t
  } = useTranslation();
  const language = i18n.language as 'bg' || 'en';
  const {
    checkLoginStatus
  } = useAuth();
  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid
    }
  } = useForm<Credentials>();
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false); // ugly hack because after isPending is completed
  // for some odd reason I'm put back on the login page for a fraction of a second and it annoys me.
  const [rememberMe, setRememberMe] = useState(false);
  const {
    mutate
  } = useLogin();
  const router = useRouter();
  const {
    query
  } = router;
  const savedData = getRememberMe();
  const {
    proceedWithPayment
  } = useProceedPayment();
  const exampleOrder = useExampleOrder();
  const onSubmit = (input: Credentials) => {
    setIsLoading(true);
    mutate(input, {
      onSuccess: response => {
        if (rememberMe) {
          setRememberMeToken(input.email, input.password);
        }
        Cookies.remove(REGISTRATION_TOKEN_STEP_2);
        Cookies.remove(REGISTRATION_TOKEN_STEP_3);
        setUserData(response.data);
        Cookies.remove(COOKIE_NEW_REGISTERED_SUPPLIER_ID);
        // new implementation
        setUserProfileType('CUSTOMER');
        checkLoginStatus(); //this in order to reset the icon at the top

        const redirectUrl = sessionStorage.getItem('redirectUrl');
        sessionStorage.removeItem('redirectUrl');
        const paymentProcessData = sessionStorage.getItem('paymentProcess');
        if (paymentProcessData) {
          const {
            plan
          }: {
            plan: MembershipPlan;
          } = JSON.parse(paymentProcessData);
          proceedWithPayment(plan);
          return;
        }
        const hasPendingOrder = localStorage.getItem('pendingOrderData');
        const orderToCreate = exampleOrder.getExampleOrder();
        if (orderToCreate || hasPendingOrder) {
          router.push('/customer/workspace/orders/create');
          return;
        }
        if (redirectUrl) {
          router.push(redirectUrl);
          return;
        }
        router.push('/categories');

        /**
         * @note : old implementation with profile action
         * i keep it here, because I might need to return to it:
         */
        // router.push("/profile-action");
      },
      //TODO: fix any type
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        Logger.captureException(error);
        setIsLoading(false);
        setServerError(error.response?.data?.error?.text || error.message);
      }
    });
  };
  return <section id="login" className="w-full md:flex-1" data-sentry-component="SignIn" data-sentry-source-file="sign-in.tsx">
      <LoaderContainer isLoading={isLoading} message="LOADER_TEXT" data-sentry-element="LoaderContainer" data-sentry-source-file="sign-in.tsx">
        <div className="md:max-w-[520px] w-full ml-auto mr-auto">
          <h1 className="text-text text-4xl pb-6" suppressHydrationWarning>
            {loginPage[language].title}
          </h1>
          <div className="text-base text-text leading-8" suppressHydrationWarning>
            <p className="mb-2">{loginPage[language].text}</p>
            <Link href="/register" suppressHydrationWarning className="text-main no-underline border-primary border-[1px] rounded-md px-4 py-2 transition-all hover:bg-primary hover:text-white" data-sentry-element="Link" data-sentry-source-file="sign-in.tsx">
              {loginPage[language].button}
            </Link>
          </div>
          <form className="space-y-3 pt-8" onSubmit={handleSubmit(onSubmit)}>
            {query.error && query.error === 'no-user' && <p className="text-red-500 text-base italic" suppressHydrationWarning>
                {loginPage[language].form.noUserError}
              </p>}
            <div className="mb-8">
              <InputBox label={loginPage[language].form.emailLabel} id="email" icon={<Mail size={20} />} type="email" defaultValue={savedData?.email} autoComplete="email" placeholder={loginPage[language].form.emailPlaceholder} required {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: loginPage[language].form.errorInvalidEmail
              }
            })} data-sentry-element="InputBox" data-sentry-source-file="sign-in.tsx" />
            </div>
            <div className="mb-10">
              <InputBox label={loginPage[language].form.passwordLabel} id="password" icon={<LockKeyhole size={20} />} type="password" defaultValue={savedData?.password} isPassword autoComplete="password" placeholder={loginPage[language].form.passwordPlaceholder} required {...register('password', {
              required: true,
              minLength: {
                value: 8,
                message: loginPage[language].form.errorInvalidPassword
              }
            })} data-sentry-element="InputBox" data-sentry-source-file="sign-in.tsx" />
            </div>
            <div className="flex justify-between mt-[-5px]">
              <div className="flex gap-2 items-center">
                <Checkbox className="rounded-none cursor-pointer data-[state=checked]:text-white" checked={rememberMe} onClick={() => {
                setRememberMe(!rememberMe);
              }} data-sentry-element="Checkbox" data-sentry-source-file="sign-in.tsx" />
                <span className="text-text font-light cursor-pointer" onClick={() => {
                setRememberMe(!rememberMe);
              }} suppressHydrationWarning>
                  {loginPage[language].form.rememberMe}
                </span>
              </div>
              <div>
                <Link className="text-text font-light" href="/forgot-password" suppressHydrationWarning data-sentry-element="Link" data-sentry-source-file="sign-in.tsx">
                  {loginPage[language].form.forgotPassword}
                </Link>
              </div>
            </div>
            <div className="">
              {errors?.password?.message && <p className="text-red-500 text-sm italic" suppressHydrationWarning>
                  {errors?.password?.message as string}
                </p>}
              {serverError && <p className="text-red-500 text-sm italic">{serverError}</p>}
            </div>
            <div className="pt-8">
              <Button type="submit" className="w-full" variant="login" size="lg" suppressHydrationWarning disabled={!isValid} data-sentry-element="Button" data-sentry-source-file="sign-in.tsx">
                {loginPage[language].form.loginButton}
              </Button>
            </div>
          </form>
          <div className="text-center text-border py-10" suppressHydrationWarning>
            {loginPage[language].form.continue}
          </div>
          <div className="flex justify-between w-[50%] ml-auto mr-auto">
            <Link href="#" className="hover:opacity-50 pointer-events-none transition-all" data-sentry-element="Link" data-sentry-source-file="sign-in.tsx">
              <Image src="/images/login/facebook.svg" alt={t('IMAGES.SIGN_IN_FACEBOOK')} width={44} height={44} className="opacity-50" data-sentry-element="Image" data-sentry-source-file="sign-in.tsx" />
            </Link>
            <Link href="#" className="hover:opacity-50 pointer-events-none transition-all" data-sentry-element="Link" data-sentry-source-file="sign-in.tsx">
              <Image src="/images/login/apple.svg" alt={t('IMAGES.SIGN_IN_APPLE')} width={44} height={44} className="opacity-50 pointer-events-none" data-sentry-element="Image" data-sentry-source-file="sign-in.tsx" />
            </Link>
            <Link suppressHydrationWarning href={process.env.NEXT_PUBLIC_GOOGLE_AUTH_LOGIN ? `${process.env.NEXT_PUBLIC_GOOGLE_AUTH_LOGIN}?language=${i18n.language === 'en' ? 'en' : 'bg'}` : `https://dev-api.ofertirai.me/api/v1/auth/google?language=${i18n.language === 'en' ? 'en' : 'bg'}`} target="_blank" className="hover:opacity-50 transition-all" data-sentry-element="Link" data-sentry-source-file="sign-in.tsx">
              <Image src="/images/login/google.svg" alt={t('IMAGES.SIGN_IN_GOOGLE')} width={44} height={44} data-sentry-element="Image" data-sentry-source-file="sign-in.tsx" />
            </Link>
          </div>
        </div>
        <ReturnRegistrationModal data-sentry-element="ReturnRegistrationModal" data-sentry-source-file="sign-in.tsx" />
      </LoaderContainer>
    </section>;
};