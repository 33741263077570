import { motion } from 'framer-motion';
import { GetStaticProps } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { SignIn } from '@/features/auth/components/sign-in';
import { useGetCanonicalUrl } from '@/hooks/use-get-canonical-url';
const Login: React.FC = () => {
  const canonicalUrl = useGetCanonicalUrl();
  const {
    t
  } = useTranslation();
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
        <title>{t('SEO.LOGIN_PAGE.TITLE')}</title>
        <meta name="description" content={t('SEO.LOGIN_PAGE.DESCRIPTION')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <meta name="keywords" content={t('SEO.LOGIN_PAGE.KEYWORDS')} data-sentry-element="meta" data-sentry-source-file="index.tsx" />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <section id="login" className="w-full pt-0 pb-4 max-w-screen-2xl flex items-center flex-col-reverse lg:flex-row ml-auto mr-auto px-6 lg:pt-20 lg:pb-20 ">
        <SignIn data-sentry-element="SignIn" data-sentry-source-file="index.tsx" />
        <motion.div className="pt-4 pb-8" initial={{
        opacity: 0,
        translateY: '75px'
      }} animate={{
        opacity: 1,
        translateY: 0
      }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
          <Image src="/images/login/phone-girl.svg" alt={t('IMAGES.SIGN_IN_OFERTIRAI_ME')} width={400} height={500} className="flex-1 w-full h-[auto] md:w-[380px] md:h-[500px] pt-0 px-10 md:px-0 md:pb-8" priority data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        </motion.div>
      </section>
    </>;
};
export const getStaticProps: GetStaticProps = async context => {
  const locale = context.locale || 'bg'; // Default to 'bg' if locale is undefined

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'bg']))
    }
  };
};
export default Login;