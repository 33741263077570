import Cookies from 'js-cookie';

import { IsPendingRegistration } from '../dtos/cookies.type';
import {
  REGISTRATION_EMAIL,
  REGISTRATION_TOKEN_STEP_2,
  REGISTRATION_TOKEN_STEP_3,
  REGISTRATION_COUNTRY_CODE,
  REGISTRATION_PHONE,
} from '../utils/constants';

export function isPendingRegistration(): IsPendingRegistration {
  const stepTwo = Cookies.get(REGISTRATION_TOKEN_STEP_2);
  const stepThree = Cookies.get(REGISTRATION_TOKEN_STEP_3);
  const email = Cookies.get(REGISTRATION_EMAIL);
  const phone = Cookies.get(REGISTRATION_PHONE);
  const countryCode = Cookies.get(REGISTRATION_COUNTRY_CODE);

  if (!stepTwo && !stepThree) {
    return {
      status: false,
    };
  }

  return {
    status: true,
    stepTwoToken: stepTwo,
    stepThreeToken: stepThree,
    email,
    phone,
    countryCode,
  };
}

export function removePendingRegistrationPrompt() {
  Cookies.remove(REGISTRATION_TOKEN_STEP_2);
  Cookies.remove(REGISTRATION_TOKEN_STEP_3);
  Cookies.remove(REGISTRATION_EMAIL);
  Cookies.remove(REGISTRATION_PHONE);
  Cookies.remove(REGISTRATION_COUNTRY_CODE);
}
